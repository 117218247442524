<template>
  <div class="craftsman-profile">
    <div class="craftsman-profile__public-page-btn-block">
      <button
          class="craftsman-profile__public-page-btn"
          @click="$router.push({
          name: 'Craftsman',
          params: { id: artisansId }
        })"
      >
        View Page
      </button>
    </div>

    <div class="craftsman-profile__banner">
      <img
          v-if="bannerImageUrl"
          :src="bannerImageUrl" :alt="getImageName(bannerImageUrl)" :title="getImageName(bannerImageUrl)" 

      >
      <img
          v-if="bannerImageUploadedUrl"
          :src="`${$options.baseUrl}${bannerImageUploadedUrl}`" :alt="getImageName(bannerImageUploadedUrl)" :title="getImageName(bannerImageUploadedUrl)" 

      >
      <div
          class="craftsman-profile__banner-text-block"
      >
        <input
            type="file"
            @change="handleBannerImage"
        >
        <input
            class="craftsman-profile__banner-text-block-h1"
            type="text"
            v-model="helloText"
            placeholder="Hello title"
        >
        <textarea
            v-model="subHelloText"
            placeholder="Hello text"
            class="craftsman-profile__banner-text-block-p"
            type="text"
        ></textarea>
      </div>
    </div>

    <div class="craftsman-profile__list-of-craftsman">
      <div class="craftsman-profile__craftsman-text">
        <input
            v-model="manufactoryTitle"
            placeholder="Manufactory title"
            class="craftsman-profile__h2"
        >
        <textarea
            v-model="manufactoryText"
            class="craftsman-profile__craftsman-subtitle"
            placeholder="Manufactory text"
        ></textarea>
      </div>

      <div class="craftsman-profile__craftsman-block">
        <div
            v-for="(item, index) in team_gallery"
            :key="index"
            class="craftsman-profile__craftsman"
        >
          <div
              class="craftsman-profile__craftsman-member-remove"
              @click="deleteMember(index)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </div>
          <div class="craftsman-profile__craftsman-img">
            <img
                v-if="item.fileUrl"
                :src="item.fileUrl"
                :alt="getImageName(item.fileUrl)" :title="getImageName(item.fileUrl)" 
            >
            <img
                v-if="item.uploadedImageUrl"
                :src="`${$options.baseUrl}${item.uploadedImageUrl}`"
                :alt="getImageName(item.uploadedImageUrl)" :title="getImageName(item.uploadedImageUrl)" 
            >
          </div>

          <div class="craftsman-profile__craftsman-name-block">
            <div class="craftsman-profile__file">
              <input
                  type="file"
                  @change="handleMemberImage($event, index)"
              >
            </div>
            <div class="craftsman-profile__name">
              <input type="text" v-model="item.name" placeholder="Name">
            </div>
            <div class="craftsman-profile__position">
              <input type="text" v-model="item.position" placeholder="Position">
            </div>
          </div>
        </div>
        <div class="craftsman-profile__craftsman-add-block">
          <button
              class="craftsman-profile__craftsman-add-btn"
              @click="addNewMemberOfTeam"
          >
            Добавить
          </button>
        </div>
      </div>
    </div>

    <div class="craftsman-profile__our-works">
      <div class="craftsman-profile__our-works-text">

        <input
            class="craftsman-profile__our-works-title"
            type="text"
            v-model="ourWorksTitle"
            placeholder="Our works title"
        >
        <textarea
            v-model="ourWorksText"
            placeholder="Our works text"
            class="craftsman-profile__our-works-subtitle"
            type="text"
        ></textarea>
      </div>
    </div>

    <div
        class="craftsman-profile__video-block"
    >
      <input
          class="craftsman-profile__video-block-title"
          type="text"
          v-model="videoTitle"
          placeholder="Video section title"
      >
      <textarea
          v-model="videoText"
          placeholder="Video subtext"
          class="craftsman-profile__video-block-subtitle"
          type="text"
      ></textarea>
      <div
          v-html="videoUrl"
          class="craftsman-profile__video-block-video-wrapper"
      ></div>
      <textarea
          v-model="videoUrl"
          placeholder="Paste video embed code here"
      ></textarea>
    </div>

    <div v-if="false" class="craftsman-profile__review-block">
      <input
          class="craftsman-profile__banner-text-block-h1"
          type="text"
          v-model="reviewTitle"
          placeholder="Review title"
      >
      <textarea
          v-model="reviewText"
          placeholder="Review text"
          type="text"
      ></textarea>
    </div>

    <div class="craftsman-profile__save-block">
      <button
          v-if="isProfileEmpty"
          class="craftsman-profile__save-btn"
          @click="createProfile"
      >
        Create Account
      </button>
      <button
          v-if="!isProfileEmpty"
          class="craftsman-profile__save-btn"
          @click="update"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

import {createNamespacedHelpers} from "vuex";

const { mapGetters } = createNamespacedHelpers('auth')
const { mapActions: Actions_alerts } = createNamespacedHelpers('alerts')

export default {
  name: "CraftsmanList",
  data () {
    return {
      artisansId: '',
      bannerImageUploadedUrl: '',
      bannerImageUrl: '',
      bannerImage: null,
      helloText: '',
      subHelloText: '',
      manufactoryTitle: '',
      manufactoryText: '',
      team_gallery: [],
      ourWorksTitle: 'OUR WORKS',
      ourWorksText: 'Each shyrdak is not just a rug, but a symbol of Kyrgyz culture and heritage, conveying the coziness and soul of the home',
      videoUrl: '',
      reviewTitle: '',
      reviewText: '',
      videoTitle: '',
      videoText: 'We use only natural materials and manual labor so that every detail reflects craftsmanship and individuality.\n' +
          'In our shyrdaks lives a story that you can make a part of your home',
      profile_pic: '',
      profile: {
        banner_1: "",
        hello_txt: "",
        about_img: "",
        about_ttl: "",
        about_txt: "",
        tam_gallery: "",
        video: "",
        video_ttl: "",
        video_txt: "",
        profile_pic: "",
        position: "",
        banner_2: "",
        bio_txt: "",
        contact: "",
        social: "{}"
      },
      isProfileEmpty: false
    }
  },
  computed: {
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    getImageName,
    ...Actions_alerts(['addAlert']),
    getProfile () {
      axios.get('/specialgoods/profile')
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          this.isProfileEmpty = true
        } else {
          this.artisansId = res.data.artisans_id
          this.profile_pic = res.data.profile_pic
          this.bannerImageUploadedUrl = res.data.banner_1
          this.helloText = res.data.hello_txt.split('</h1>')[0].split('<h1>')[1]
          this.subHelloText = res.data.hello_txt.split('<p>')[1].split('</p>')[0]
          this.manufactoryTitle = res.data.about_ttl.split('</h2>')[0].split('<h2>')[1]
          this.manufactoryText = res.data.about_txt.split('<p>')[1].split('</p>')[0]
          this.team_gallery = res.data.tam_gallery.map((member) => {
            return {
              uploadedImageUrl: member.imageUrl,
              fileUrl: null,
              file: {},
              name: member.name,
              position: member.position
            }
          })
          this.ourWorksTitle = res.data.bio_txt.split('</h2>')[0].split('<h2>')[1] || this.ourWorksTitle

          const ourWorksText = res.data.bio_txt.split('<p>')[1].split('</p>')[0]
          this.ourWorksText =  ourWorksText || this.ourWorksText

          this.videoUrl = res.data.video
          this.videoTitle = res.data.video_ttl.split('<h2>')[1].split('</h2>')[0]
          this.videoText = res.data.video_txt.split('<p>')[1].split('</p>')[0] || this.videoText
        }
      })
    },
    createProfile () {
      let obj = {
        ...this.profile
      }

      obj.hello_txt = `<h1>${this.helloText}</h1><p>${this.subHelloText}</p>`
      obj.tam_gallery = JSON.stringify(this.team_gallery.map((member) => ({ name: member.name, position: member.position })))
      obj.about_ttl = `<h2>${this.manufactoryTitle}</h2>`
      obj.about_txt = `<p>${this.manufactoryText}</p>`
      obj.bio_txt = `<h2>${this.ourWorksTitle}</h2><p>${this.ourWorksText}</p>`
      obj.video = this.videoUrl

      axios.post('/specialgoods/profile', obj)
      .then((res) => {
        this.addAlert({ type: 'success', text: 'Successfully created' })
      })
    },
    uploadImage (image) {
      let form = new FormData()

      form.append('photos[]', image)

      return axios.post('/frontend/upload-photos', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    handleBannerImage (e) {
      if (!this.bannerImageUploadedUrl) {
        this.bannerImageUploadedUrl = ''
        const file = e.target.files[0];
        this.bannerImage = file
        this.bannerImageUrl = URL.createObjectURL(file);
        this.update()
      } else {
        this.deleteImage(this.bannerImageUploadedUrl)
            .then(() => {
              this.bannerImageUploadedUrl = ''
              const file = e.target.files[0];
              this.bannerImage = file
              this.bannerImageUrl = URL.createObjectURL(file);
              this.update()
            })
      }
    },
    deleteImage (url) {
      const payload = {filename: url}
      return axios.post('/frontend/upload-delete', payload)
    },
    handleMemberImage (e, index) {
      const file = e.target.files[0];

      this.team_gallery = this.team_gallery.map((member, i) => {
        if (index === i) {
          member.file = file
          member.fileUrl = URL.createObjectURL(file);
          member.uploadedImageUrl = ''
        }

        return member
      })
    },
    deleteMember (index) {
      let deleteUrl = this.team_gallery.find((member, i) => index === i).uploadedImageUrl

      if (deleteUrl) {
        this.deleteImage(deleteUrl)
            .then(() => {
              this.update()
            })
      }

      this.team_gallery = this.team_gallery.filter((member, i) => index !== i)
    },
    addNewMemberOfTeam () {
      this.team_gallery.push({
        uploadedImageUrl: '',
        fileUrl: null,
        file: {},
        name: '',
        position: ''
      })
    },
    async update () {
      let obj = {
        ...this.profile
      }

      if (this.bannerImage) {
        const bannerImageUploadResult = await this.uploadImage(this.bannerImage)
        if (bannerImageUploadResult.data.success) {
          this.bannerImageUploadedUrl = bannerImageUploadResult.data.files[0]
          this.bannerImage = null
          this.bannerImageUrl = ''
        }
      }

      const teamImages = []
      let tmpTeamGallery = []

      this.team_gallery.forEach((member) => {
        if (member.fileUrl) {
          teamImages.push(this.uploadImage(member.file))
        }
        tmpTeamGallery.push(member)
      })

      const teamImageUploadResult = await Promise.all(teamImages);
      teamImageUploadResult.forEach((response) => {
        if (response.data.success) {
          for (let i = 0; i < tmpTeamGallery.length; i++) {
            let tmpMember = tmpTeamGallery[i]

            if (!tmpMember.uploadedImageUrl) {
              tmpMember.uploadedImageUrl = response.data.files[0]
              tmpMember.fileUrl = ''
              tmpMember.file = {}
              break
            }
          }
        }
      })

      this.team_gallery = tmpTeamGallery
      tmpTeamGallery = []

      obj.profile_pic = this.team_gallery.length ? this.team_gallery[0].uploadedImageUrl : ''
      obj.hello_txt = `<h1>${this.helloText}</h1><p>${this.subHelloText}</p>`
      obj.banner_1 = this.bannerImageUploadedUrl
      obj.tam_gallery = JSON.stringify(this.team_gallery.map((member) => ({ name: member.name, position: member.position, imageUrl: member.uploadedImageUrl })))
      obj.about_ttl = `<h2>${this.manufactoryTitle}</h2>`
      obj.about_txt = `<p>${this.manufactoryText}</p>`
      obj.bio_txt = `<h2>${this.ourWorksTitle}</h2><p>${this.ourWorksText}</p>`
      obj.video = this.videoUrl || ''
      obj.video_ttl = `<h2>${this.videoTitle}</h2>`
      obj.video_txt = `<p>${this.videoText}</p>`

      await axios.put('/specialgoods/profile', obj)

      this.addAlert({ type: 'success', text: 'Successfully updated' })
    }
  },
  baseUrl: 'https://admin.tumar.com/storage/' //'http://ovz2.j1146928.m6x5m.vps.myjino.ru/storage/'
}
</script>

<style lang="scss">
.craftsman-profile {
  color: #39423E;
  max-width: 1440px;
  margin: 0 auto;

  &__public-page-btn-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__public-page-btn {
    position: fixed;
    z-index: 100;
    bottom: 10px;
    height: fit-content;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    color: #666666;
    line-height: 18px;
    border-radius: 2px;
    text-transform: uppercase;
    background-color: #ffc721;
    letter-spacing: 0.13em;
  }

  &__save-block {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__save-btn {
    background: #0a58ca;
    color: #fff;
    padding: 10px 20px;
  }

  &__review-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  &__video-block {
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &-video-wrapper {
      display: flex;
      justify-content: center;
    }

    &-title {
      color: #39423E;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 154%;
      text-transform: uppercase;
    }
    &-subtitle {
      min-height: 50px;
      background: #fff;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 127.49%;
    }

    input {
      min-width: 300px;
    }
  }
  &__h2 {
    color: #39423E;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 154%;
    text-transform: uppercase;
  }

  &__banner {
    position: relative;
    height: 329px;
    background: transparent;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &-text-block {
      position: absolute;
      top: 82px;
      color: #fff;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 331px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 10px;

      input {
        width: 100%;
        color: #000;
      }

      &-h1{
        background: #fff;
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 154%;
        text-transform: uppercase;
      }

      &-p {
        width: 100%;
        min-height: 50px;
        background: #fff;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 127.49%;
      }
    }
  }

  &__list-of-craftsman {
    max-width: 726px;
    margin: 64px auto 0;
  }
  &__craftsman-text {
    max-width: 572px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 0 auto;
  }
  &__craftsman-subtitle {
    color: #39423E;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.8%;
  }
  &__craftsman-block {
    margin-top: 54px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 336px;
    gap: 10px;
  }
  &__craftsman {
    position: relative;
    z-index: 1;

    &-member-remove {
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      z-index: 2;

      &:hover {
        cursor: pointer;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-add-block {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ccc;
    }
    &-add-btn {
      background: grey;
      padding: 5px 10px;
      color: #fff;
    }
    &-img {
      background: darkgrey;
      width: 235px;
      height: 336px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-name-block {
      position: absolute;
      z-index: 2;
      bottom: 20px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  &__file {
    margin: 0 auto 5px;
  }
  &__name {
    color: #666;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 154%;
    font-variant: small-caps;
    margin-bottom: 10px;

    input {
      background: #fff;
      color: #000;
      width: 90%;
    }
  }
  &__position {
    color: #3E4151;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.49%;

    input {
      background: #fff;
      color: #000;
      width: 90%;
    }
  }
  &__our-works {
    margin-top: 108px;

    &-text {
      max-width: 439px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
    &-title {
      color: #39423E;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 154%;
      text-transform: uppercase;
      margin: 0;
    }
    &-subtitle {
      color: #39423E;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 152.8%;
    }
    &-images {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-top,
    &-bottom {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr;
      height: 390px;
    }
    &-top {
      &-left {
        display: flex;
        gap: 8px;

        &-img-1, &-img-2 {
          background: grey;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      &-right {
        background: grey;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-bottom {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr;
      height: 390px;

      &-left {
        background: grey;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &-right {
        display: flex;
        gap: 8px;

        &-img-1, &-img-2 {
          background: grey;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  &__reviews {
    margin: 126px auto 0;
    max-width: 977px;

    &-text {
      max-width: 440px;
      margin: 0 auto 64px;
    }
    &-subtitle {
      color: #39423E;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 152.8%;
    }
  }
  &__sort-review-block {
    color: #0D0C22;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__button-write-review {
    border: 1px solid #39423E;
    background: #CFEBCC;
    padding: 9px 28px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__reviews {
    margin-bottom: 120px;
  }
  &__reviews-wrapper {

  }
  &__replies-block {
    width: 80%;
    margin-left: 100px;
  }
  &__review {
    max-width: 634px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #F8F7F4;
    padding-bottom: 30px;
    margin-top: 56px;

    &-delete {
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    &-date {
      color: #858585;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  &__reviewer {
    display: flex;
    column-gap: 12px;
    align-items: center;

    &-avatar {
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50%;
      background: #5465FF;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-name {
      color: #0D0C22;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
    &-position {
      color: #858585;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
    &-text {
      color: #0D0C22;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__review-modal {
    padding: 20px 32px;

    &-message-title {
      font-size: 14px !important;
    }

    &-header {
      position: sticky;
      top: 20px;
      right: 7px;
      height: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-close {
        width: 30px;
        height: 30px;
        background: white;

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 100%;
          height: 100%;
          color: #CFCFCF;
          transform: rotate(-90deg);
        }
      }
    }

    &-h2 {
      margin-bottom: 7px;
      color: #0D0C22;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
    }
    &-p {
      color: #0D0C22;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 200% */
      margin-bottom: 14px !important;
    }
    &-textarea {
      border: 1px solid #F6F6F6;
      background: #F6F6F6;
      outline: none;
      width: 100%;
      height: 130px;
      padding: 17px 22px;
    }
    &-input-block {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
    }
    &-label {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-input {
      border: 1px solid #CFCFCF;
      background: #FFF;
      padding: 9px 28px;
      outline: none;
    }
    &-button {
      border: 1px solid #39423E;
      background: #CFEBCC;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 36px 0 24px;

      &_disabled {
        background: darkgrey;
        color: grey;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
    &-under-text {
      color: #0D0C22;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-rating-block {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin: 20px 0;
      font-size: 14px;
    }
  }
}
</style>
